<template>
  <section class="section-block">
    <SectionTitle title="報到操作設定" hideBtn />
    <BaseElForm :model="formData" label-position="left" label-width="200px">
      <BaseElFormItem v-if="useIndoorControl" label="顯示開門按鈕">
        <BaseElSwitch
          v-model="formData.enableOpenDoor"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'enableOpenDoor', formData.enableOpenDoor)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示前往預約按鈕">
        <template slot="label">
          <div class="form-item-tooltip-lable flex items-center" style="gap: 8px">
            <p class="text-sub">顯示前往預約按鈕</p>
            <el-tooltip placement="right">
              <div slot="content" style="width: 200px">
                引導當日無預約紀錄的消費者前往<br>
                LINE 官方帳號進行預約
              </div>
              <span class="material-icons text-[16px]">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <BaseElSwitch
          v-model="formData.displayActionAppointmentPage"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayActionAppointmentPage', formData.displayActionAppointmentPage)"
        />
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { usePermissions } from '@/use/permissions'
import { computed, ref, defineComponent, watch } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
export default defineComponent({
  name: 'CheckInSetting',
  components: {
    SectionTitle,
  },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const showEditModal = ref(false)
    const { checkAction } = usePermissions()
    const useIndoorControl = computed(() => checkAction('admin.indoorControlConfig.page'))

    const formData = ref({
      displayActionAppointmentPage: false,
      enableOpenDoor: false,
    })
    const syncData = async () => {
      formData.value = {
        displayActionAppointmentPage: get(props, 'content.displaySetting.displayActionAppointmentPage'),
        enableOpenDoor: get(props, 'content.enableOpenDoor'),
      }
    }
    watch(props, async () => {
      await syncData()
    })

    return { showEditModal, formatDate, formData, useIndoorControl }
  },
})
</script>
