<template>
  <BaseDialog
    width="600px"
    title="編輯"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm
      ref="formRef"
      label-position="top"
      label-width="250px"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem prop="timeFieldName" label="欄位顯示名稱">
        <template slot="label">
          <span>欄位顯示名稱</span>
          <p class="text-sm text-gray-80 mt-[8px]">至多可設定 5 個字（含英文字母）</p>
        </template>
        <BaseElInput v-model="formData.timeFieldName" placeholder="名稱預設為 使用時段" />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import formUtils from '@/utils/form'
import { noEmptyRules, maxRules } from '@/validation'
import { UpdateCheckInPage } from '@/api/checkInPage'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { get } from 'lodash'

export default defineComponent({
  name: 'TimeRangeNameEditModal',
  components: {
    BaseDialog,
  },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  emits: ['refresh', 'close', 'confirm'],
  setup (props, { emit }) {
    const loading = ref(null)
    const formRef = ref(null)
    const formData = reactive({
      timeFieldName: null,
    })
    const { shopId } = useShop()

    const syncData = () => {
      formData.timeFieldName = get(props, 'data.timeFieldName')
    }

    const formRules = {
      timeFieldName: [maxRules(5)],
    }

    const updateCheckInPage = async () => {
      loading.value = true
      const [, err] = await UpdateCheckInPage({
        shopId: shopId.value,
        displaySetting: {
          ...props.data,
          timeFieldName: get(formData, 'timeFieldName') || '使用時段',
        },
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updateCheckInPage()
      loading.value = false
      emit('refresh')
      emit('close')
    }
    onMounted(() => {
      syncData()
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
    }
  },
})
</script>
