<template>
  <section class="section-block">
    <SectionTitle title="內容顯示設定" hideBtn />
    <BaseElForm :model="formData" label-position="left" label-width="200px">
      <BaseElFormItem label="顯示分店名稱">
        <BaseElSwitch
          v-model="formData.displayShopName"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayShopName', formData.displayShopName)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示門市名稱">
        <BaseElSwitch
          v-model="formData.displayBranchName"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayBranchName', formData.displayBranchName)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示服務項目">
        <BaseElSwitch
          v-model="formData.displayAppointmentService"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayAppointmentService', formData.displayAppointmentService)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示服務人員">
        <BaseElSwitch
          v-model="formData.displayAppointmentUnit"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayAppointmentUnit', formData.displayAppointmentUnit)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示會員姓名">
        <BaseElSwitch
          v-model="formData.displayMemberName"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayMemberName', formData.displayMemberName)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示電話後3碼">
        <BaseElSwitch
          v-model="formData.displayMemberPhone"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayMemberPhone', formData.displayMemberPhone)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示出生月日">
        <BaseElSwitch
          v-model="formData.displayMemberBirthday"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayMemberBirthday', formData.displayMemberBirthday)"
        />
      </BaseElFormItem>

      <BaseElFormItem label="顯示訂單編號">
        <BaseElSwitch
          v-model="formData.displayOrderCode"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="$emit('updateCheckInPage', 'displayOrderCode', formData.displayOrderCode)"
        />
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { usePermissions } from '@/use/permissions'
import { computed, ref, defineComponent, watch } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
export default defineComponent({
  name: 'ContentShowSetting',
  components: {
    SectionTitle,
  },
  props: {
    functionMenuConfig: {
      type: Array, default: () => [],
    },
    content: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const showEditModal = ref(false)
    const { checkAction } = usePermissions()
    const usePospalTransaction = computed(() => checkAction('adminView.memberCenterConfig.posPalMemberTransaction'))
    const usePospalPassProduct = computed(() => checkAction('adminView.memberCenterConfig.posPalMemberPassProduct'))
    const usePospalBalance = computed(() => checkAction('adminView.memberCenterConfig.findPosPalMemberWalletRecord'))
    const useExternalTransaction = computed(() => checkAction('adminView.memberCenterConfig.memberExternalTransaction'))
    const useSalesRecord = computed(() => checkAction('admin.salesRecord.page'))

    const formData = ref({
      displayShopName: false,
      displayBranchName: false,
      displayAppointmentService: false,
      displayAppointmentUnit: false,
      displayMemberName: false,
      displayMemberPhone: false,
      displayMemberBirthday: false,
      displayOrderCode: false,
    })
    const syncData = async () => {
      const {
        displayShopName,
        displayBranchName,
        displayAppointmentService,
        displayAppointmentUnit,
        displayMemberName,
        displayMemberPhone,
        displayMemberBirthday,
        displayOrderCode,
      } = get(props.content, 'displaySetting')
      formData.value = {
        displayShopName,
        displayBranchName,
        displayAppointmentService,
        displayAppointmentUnit,
        displayMemberName,
        displayMemberPhone,
        displayMemberBirthday,
        displayOrderCode,
      }
    }
    watch(props, async () => {
      await syncData()
    })

    return { usePospalTransaction, usePospalBalance, useExternalTransaction, showEditModal, formatDate, usePospalPassProduct, useSalesRecord, formData }
  },
})
</script>
